.svg-icon {
  display: inline-block;
  vertical-align: top;
  color: inherit;
  fill: currentColor;
  pointer-events: none;

  &.inactive {
    fill: $pinkish-grey;
  }
}

.profile-icon {
  color: $mainTenantColor;
}

.help-mark {
  display: block;
  width: 40px;
  height: 40px;
  background-color: #ffffff;
  border-radius: 100%;
  border: solid 2px $mainTenantColor;

  font-family: $header-font;
  font-size: 20px;
  line-height: 36px;
  font-weight: bold;
  text-align: center;
  color: $mainTenantColor;
}
