.bc-dropdown__button {
  outline: none;
  border: none;

  display: inline-block;
  position: relative;
  padding-right: 30px;
  &_center-text {
    text-align: center;
    padding-left: 30px;
  }
  &_wide {
    width: 100%;
  }
}

.bc-dropdown_status__button {
  padding-top: 8px;
  padding-bottom: 10px;
  border-radius: 5px;
  font-size: 13px;
  letter-spacing: 0.9px;
  line-height: 19px;
  font-weight: 300;
  font-family: $header-font;
  color: #fff;
  background: $pinkish-grey;
  box-shadow: 0 3px 0 0 $greyish-two;

  &_incomplete {
    background: $sunflower-yellow;
    box-shadow: 0 3px 0 0 $ugly-yellow;
  }

  // TODO check colors
  &_complete {
    background: $light-sky-blue;
    box-shadow: 0 3px 0 0 #70c8ff;
  }
  // TODO check this color with Lucas
  &_done {
    background: $dark-sky-blue;
    box-shadow: 0 3px 0 0 #3a95df;
  }
  &_accepted {
    background: $mainTenantColor;
    box-shadow: 0 3px 0 0 $secondTenantColor;
  }
  &_rejected {
    background: $dark-coral;
    box-shadow: 0 3px 0 0 #b43131;
  }
}

.bc-dropdown.open .bc-dropdown__button {
  border-radius: 5px 5px 0 0;
  &.bc-dropdown_status__button {
    background-color: $greyish-two;
    box-shadow: 0 3px 0 0 $warm-grey-two;
    &_incomplete {
      background-color: $ugly-yellow;
      box-shadow: 0 3px 0 0 #be9c00;
    }
    // TODO check this color with Lucas
    &_complete {
      background-color: $light-sky-blue;
      box-shadow: 0 3px 0 0 #3672a3;
    }
    &_done {
      background: $dark-sky-blue;
      box-shadow: 0 3px 0 0 #3a95df;
    }
    &_accepted {
      background-color: $secondTenantColor;
      box-shadow: 0 3px 0 0 $ocean-green;
    }
    &_rejected {
      background-color: #af4040;
      box-shadow: 0 3px 0 0 #943636;
    }
  }
}

.bc-dropdown__icon {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  color: $charcoal-grey;
}

.dropdown-menu.bc-dropdown__menu {
  z-index: 10;
  width: 100%;
  min-width: 0;
  padding: 0;
  margin: -3px 0 0;
  border-radius: 0;
  border: none;
  box-shadow: $typical-box-shadow;

  &.bc-dropdown__menu_center-text {
    text-align: center;
  }
  &.bc-dropdown__menu_right-text {
    text-align: right;
  }

  li {
    background-color: #fff;
    &:last-of-type a {
      box-shadow: 0 3px 0 0 $pinkish-grey;
      border-radius: 0 0 5px 5px;
    }
  }

  a {
    display: block;
    padding: 8px 5px 10px;
    background: #fff;
    border-bottom: 1px solid $pinkish-grey;
    white-space: nowrap;
    text-decoration: none;
    cursor: pointer;

    &:hover, &:active, &:focus, &_active {
      box-shadow: none;
      text-decoration: none;
      color: $charcoal-grey;
    }
    &:hover, &:active, &_active {
      background: rgba($mainTenantColor, 0.08);
    }
  }
}
