@import url('https://fonts.googleapis.com/css?family=Montserrat:300,400');
@import url('https://fonts.googleapis.com/css?family=Roboto');
/*----------------------------------------------------------------------------------*/
/*------------------------------RESET DEFAULT STYLES--------------------------------*/
/*----------------------------------------------------------------------------------*/
html,body                       { width:100%; height:100%; }
html                            { -webkit-text-size-adjust: none; }
body                            { padding:0; margin:0; font:14px 'Roboto', sans-serif; color:#333; }
div,p,a,span,em,strong,img,h1,h2,h3,h4,h5,h6,ul,ol,li,dl,dt,dd,table,td,tr,form,fieldset,label,select,input,textarea {
  padding:0;
  margin:0;
  outline:none;
  list-style:none;
}
select, input, textarea        { font-size:14px; vertical-align:middle; }
textarea                       { vertical-align:top; }
:focus                         { outline:none; }
input[type=submit]             { cursor:pointer; }
img, fieldset                  { border:0; }
table                          { border-collapse:collapse; border-spacing:0; }
a:link, a:visited              { text-decoration:none; }
a:hover                        { text-decoration:none; }
/*----------------------------------------------------------------------------------*/
/*-------------------------------------HTML5 BLOCKS---------------------------------*/
/*----------------------------------------------------------------------------------*/
header,
footer,
nav,
section,
article,
aside,
main {
  display: block;
}
*{
  box-sizing: border-box;
}
