.bc-toaster {
  padding: 10px 20px 10px 15px;
  width: fit-content;
  display: flex;
  align-items: center;

  &_warning {
    background: $light-orange;

    .svg-icon {
      color: $orange;
    }
  }

  &_success {
    background: $light-green;

    .svg-icon {
      color: $green;
    }
  }

  &_super-warning {
    background: $light-red;

    .svg-icon {
      fill: $darker-red;
    }
  }

  .svg-icon {
    margin-right: 10px;
    flex-shrink: 0;
    align-self: flex-start;
  }

  .text-link {
    font-size: inherit;
    line-height: inherit;
  }
}
