.content__wrap {
  position: relative;
  min-height: calc(100% - 60px);
  padding-bottom: 80px;

  @include breakpoint(md-max) {
    .container {
      width: auto;
    }
  }
}

.heading_row {
  display: flex;
  align-content: center;

  height: 147px;

  [class*=col-] {
    display: flex;
    align-items: center;
  }

  .h1-header {
    margin: auto;
  }
}

.content_row {
  margin-right: -12px;
  margin-left: -12px;

  [class*=col] {
    padding-left: 12px;
    padding-right: 12px;
  }
}

.buttons_row {
  margin-top: 15px;
  margin-right: -6px;
  margin-left: -6px;

  [class*=col-] {
    padding-left: 6px;
    padding-right: 6px;
  }
  @include breakpoint(sm-max) {
    .bc-btn + .bc-btn {
      margin-top: 15px;
    }
  }
}

.content-block {
  padding: 0 30px 25px;

  background-color: #ffffff;
  box-shadow: $typical-box-shadow;

  &_header {
    display: flex;
    justify-content: center;
    align-items: center;

    height: 70px;
    padding: 10px;
    margin: 0 -30px;

    box-sizing: border-box;
    border-bottom: 1px solid $whisper-grey;

    font-size: 16px;

    & + .main-text {
      margin-top: 18px;
    }
  }

  .main-text {
    line-height: 24px;
  }

  .row {
    margin-right: -6px;
    margin-left: -6px;
    [class*=col-] {
      padding-left: 6px;
      padding-right: 6px;
    }
  }
}
