.dx-layout-manager .dx-field-item:not(.dx-first-row) {
  padding-top: 15px;
}

.dx-field-item-label-text {
  font-family: $header-font;
  font-weight: 500;
  font-size: 14px;
  color: $charcoal-grey;
}

.dx-field-item-label-location-top {
  margin: 0 0 5px 0;
}

.dx-texteditor {
  border: solid 1px $pinkish-grey;
  border-radius: 5px;

  &.dx-state-focused,
  &.dx-state-active {
    border-color: $pinkish-grey;
    box-shadow: inset 0 0 1px $pinkish-grey;
  }
}

.dx-texteditor-input {
  min-height: 47px;
  padding: 10px 15px;
  border-radius: 5px;
}

.dx-placeholder:before {
  padding-left: 15px;
  font-family: $text-font;
  font-weight: 300;
  font-size: 14px;
  line-height: 1.71;
  text-align: left;
  color: $greyish;
}
