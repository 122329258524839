.bc-panel {
  @include u-clearfix;
  flex: 1;
  padding: 25px 0 0;
  border-radius: 0;
  box-shadow: $typical-box-shadow;
  border: none;
}

.bc-panel__heading {
  margin: 0 30px 20px;
}

.bc-panel__content {
 padding: 0 30px 25px;
}

.bc-panel__footer {
  margin: 20px 0 0;
  padding: 20px 0 0;
  border-top: 1px solid $whisper-grey;
}

.bc-panel__footer-grey {
  padding: 20px 30px;
  background: $white-smoke;
}


.bc-panel_bottom-btn {
  position: relative;
  padding-bottom: 98px;

  &__footer {
    position: absolute;
    right: 30px;
    left: 30px;
    bottom: 25px;
  }
}
