.bc-new-grid {
  .dx-datagrid-nowrap {
    border: 0;
  }

  .dx-datagrid-headers .dx-datagrid-table .dx-row > td,
  .dx-header-row, .dx-data-row,
  .dx-datagrid-header-panel {
    border-bottom: 0;
  }

  .dx-datagrid-borders .dx-datagrid-rowsview,
  .dx-datagrid-headers + .dx-datagrid-rowsview {
    border-top: 0;
  }

  .dx-datagrid-table .dx-row > td {
    font-size: 14px;
    font-family: $text-font;
    color: $charcoal-grey;
  }

  .dx-datagrid-table .dx-header-row .dx-datagrid-text-content {
    font-family: $header-font;
  }

  .dx-datagrid-content .dx-datagrid-table .dx-row > td {
    padding: 8px 10px 8px 0;
  }

  .dx-datagrid-content .dx-datagrid-table .dx-row .dx-command-select {
    width: 50px;
    min-width: 50px;
    max-width: 50px;
  }

  .dx-buttongroup-item.dx-button.dx-button-mode-text.dx-state-focused,
  .dx-buttongroup-item.dx-button.dx-button-mode-text.dx-state-hover {
    background-color: transparent;
  }

  .dx-texteditor-input {
    min-height: 40px;
  }
}

