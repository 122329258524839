.dx-popup-wrapper>.dx-overlay-content {
  border: 1px solid $pinkish-grey;
  border-radius: 0 0 5px 5px;
}
.dx-dropdownlist-popup-wrapper .dx-popup-content {
  padding: 0px;
}
.dx-dropdownlist-popup-wrapper .dx-list:not(.dx-list-select-decorator-enabled) .dx-list-item-content {
  padding-top: 13px;
  padding-bottom: 13px;
}
.dx-dropdownlist-popup-wrapper .dx-list-item:last-of-type {
  border-top: 1px solid $pinkish-grey;
}
.dx-list:not(.dx-list-select-decorator-enabled) .dx-list-item.dx-state-active {
  background-color: $mainTenantColor;
}
