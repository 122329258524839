.btn {
  display: block;
  color: #ffffff;
  font-size: 14px;
  border-radius: 5px;
  padding: 0 0;
  cursor: pointer;
  &:disabled {
    opacity: 0.5;
    cursor: default;
  }
}

.dx-btn {
  .dx-button {
    border-color: transparent;
    background: transparent;
    width: 100%;
  }
  .dx-button-content {
    padding: 15px 10px 15px;
    box-shadow: none;
    color: #ffffff;
    font-size: 14px;
    border-radius: 5px;
  }
  .dx-button-text {
    font-family: $header-font;
  }
}

.dx-btn__success {
  .dx-button-content {
    background-color: $mainTenantColor;
  }
  .dx-state-hover > .dx-button-content {
    background-color: $secondTenantColor;
  }
}

.dx-btn__default {
  .dx-button-content {
    background-color: $pinkish-grey;
  }
  .dx-state-hover > .dx-button-content {
    background-color: $greyish-two;
  }
}

.bc-btn {
  padding: 13px 19px 15px 19px;
  margin: 0;
  border: none;
  border-radius: 5px;
  box-shadow: none;
  font-size: 14px;
  line-height: 19px;
  font-family: $header-font;
  font-weight: 400;
  color: #fff;

  &:hover,
  &:active,
  &:focus {
    color: #fff;
  }

  &:focus {
    outline: none;
  }

  &_small {
    padding-top: 10px;
    padding-bottom: 11px;
  }

  &_tiny {
    padding-top: 8px;
    padding-bottom: 10px;
  }

  &_default {
    background-color: $pinkish-grey;
    &:hover {
      background-color: $greyish-two;
    }
    &:active,
    &:focus,
    &.bc-btn_disable,
    &:disabled {
      background-color: $pinkish-grey;
      &:hover {
        background-color: $pinkish-grey;
      }
    }
  }

  &_custom {
    background-color: $mainTenantColor;
    &:hover {
      background-color: $secondTenantColor;
    }
    &:active,
    &:focus,
    &.bc-btn_disable,
    &:disabled {
      background-color: $mainTenantColor;
      &:hover {
        background-color: $mainTenantColor;
      }
    }
  }

  &_disable,
  &:disabled {
    opacity: 0.6;
  }

  &_block {
    display: block;
    width: 100%;
  }

  &__icon {
    display: inline-block;
    opacity: 0.2;
    color: inherit;
    &_left {
      margin-right: 5px;
    }
    &_topfix {
      vertical-align: -2px;
    }
  }
}

.bc-btn-group {
  &_vertical {
    .bc-btn + .bc-btn {
      margin-top: 10px;
      margin-left: 0;
    }
  }
}

.bc-btn_bordered {
  background: transparent;
  border: 1px solid $pinkish-grey;
  color: $charcoal-grey;

  &.bc-btn_active,
  &:hover,
  &.bc-btn_active:active,
  &.bc-btn_active:focus {
    border-color: $pinkish-grey;
    background: $white-smoke2;
    color: $charcoal-grey;
  }

  &:active,
  &:focus,
  &:disabled,
  &.bc-btn_disable,
  &.bc-btn_disable:hover,
  &:disabled:hover {
    border-color: $pinkish-grey;
    background: #fff;
    color: $charcoal-grey;
  }
  &_disable,
  &:disabled {
    opacity: 0.5;
  }
}

.bc-btn_back {
  &::before {
    content: '';
    display: inline-block;
    vertical-align: 0;
    width: 0;
    height: 0;
    margin-right: 8px;
    border-style: solid;
    border-width: 5px 7px 5px 0;
    border-color: transparent $mainTenantColor transparent transparent;
  }

  &:hover {
    &:before {
      border-color: transparent $secondTenantColor transparent transparent;
    }
  }
}
