.tabs-container {
  padding: 20px 30px 0;
  background-color: #ffffff;

  .new_bc-tabs-block {
    padding: 0;
  }
}

.new_bc-tabs-block {
  border-bottom: 2px solid $section-background;
  padding: 0 4px;

  .new_bc-tab-radio {
    display: none;

    &:checked + .new_bc-tab-label {
      border-bottom: solid 2px $mainTenantColor;
      font-weight: 500;
      color: #2a2a23
    }
  }

  .new_bc-tab-label {
    padding-bottom: 6px;
    margin: 0 20px -2px 0;
    z-index: 5;

    font-weight: lighter;

    text-transform: uppercase;
    font-family: $header-font;
    font-size: 14px;
    text-align: center;
    color: #9b9b9b;

    &:hover {
      border-bottom: solid 2px $mainTenantColor;
      cursor: pointer;
    }
  }
}
