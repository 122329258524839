@import '../core/variables';

.bc-new-modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1200;
  background: rgba(42, 42, 50, 0.3);
  overflow-x: hidden;
  overflow-y: auto;

  .modal-content {
    border: none;
    box-shadow: none;
  }

  .modal-header {
    position: relative;
    height: 70px;
    padding: 24px;
    box-shadow: none;
    text-align: left;
    color: $charcoal-grey;
    border: 0;

    .modal-title {
      font-family: $header-font;
      font-size: 20px;
      font-weight: 400;
    }

    .close {
      position: absolute;
      top: 21px;
      right: 26px;
      padding: 0;
      margin: 0;
      color: $charcoal-grey;
      opacity: 1;
    }
  }

  .modal-body {
    padding: 10px 24px 0;
  }

  .modal-footer {
    padding: 24px;
    border-top: none;
  }

  .justify-buttons {
    display: flex;
    justify-content: space-between;

    .btn + .btn {
      margin-left: 20px;
    }
  }

  .buttons-flex-end {
    display: flex;
    justify-content: flex-end;

    .btn + .btn {
      margin-left: 10px;
    }
  }
}

.modal-dialog_xs {
  width: 460px;
}
.modal-dialog_sm {
  width: 630px;
}
.modal-dialog_large {
  width: 1140px;
}
