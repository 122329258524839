.field__item {
  margin-bottom: 15px;
}

.field__label {
  margin-bottom: 4px;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.71;
  font-family: $header-font;
  color: $charcoal-grey;
  &_bolder {
    font-size: 15px;
    font-weight: bold;
  }
  &_disabled {
    color: $warm-grey-three;
  }
}

.field__help-block {
  margin: 2px 0 0;
  font-family: $text-font;
  font-size: 12px;
  font-weight: normal;
  line-height: 1.58;
  color: $charcoal-grey;
}

.error-message {
  font-size: 12px;
  line-height: 19px;
  font-weight: normal;
  font-family: $text-font;
  color: $dark-coral;
}

.checkbox__field,
.radio__field {
  position: relative;
  min-height: 24px;
}

.radio__field input[type=radio] {
  position: absolute;
  visibility: hidden;
  &:checked ~ .radio__check {
    border: 2px solid $mainTenantColor;
    background-color: $mainTenantColor;
  }

  &:checked ~ .radio__label {
    color: $charcoal-grey;
  }

  &[disabled] ~ .radio__check {
    background-color: $whisper-grey;
    border-color: $pinkish-grey;
  }

  &[disabled] ~ .radio__label,
  &[disabled]:checked ~ .radio__check {
    opacity: 0.6;
  }
}

.checkbox__field input[type=checkbox] {
  position: absolute;
  visibility: hidden;
  &:checked ~ .checkbox__check {
    border: 2px solid $mainTenantColor;
    &:before {
      content: "";
      position: absolute;
      top: 1px;
      left: 4px;
      width: 6px;
      height: 10px;
      border-width: 0 2px 2px 0;
      border-style: solid;
      border-color: $mainTenantColor;
      transform: rotate(45deg);
    }
  }

  &:checked ~ .checkbox__label {
    color: $charcoal-grey;
  }

  &[disabled] ~ .checkbox__check {
    background-color: $whisper-grey;
    border-color: $pinkish-grey;
  }
  &[disabled]:checked ~ .checkbox__check {
    opacity: 0.6;
  }
}

.checkbox__label,
.radio__label {
  position: relative;
  z-index: 3;
  display: block;
  margin: 0;
  padding: 0 0 0 27px;
  cursor: pointer;
  text-transform: capitalize;
  font-size: 14px;
  line-height: 27px;
  font-weight: 400;
  font-family: $text-font;
  color: $charcoal-grey;
}

.checkbox__check,
.radio__check {
  display: block;
  position: absolute;
  border: 2px solid $pinkish-grey;
  height: 18px;
  width: 18px;
  top: 3px;
  left: 0;
  z-index: 2;
  transition: border .25s linear;
}

.radio__check {
  border-radius: 100%;
}

.calendar_field {
  position: relative;
  &__icon {
    position: absolute;
    right: 17px;
    top: 15px;
  }

  .ui-calendar {
    display: block;
  }

  .form-control {
    height: 47px;
    width: 100%;
    padding: 12px 50px 12px 14px;
    border: solid $pinkish-grey;
    border-radius: 5px;
    box-shadow: none;
    cursor: pointer;
    font-size: 14px;
    line-height: 24px;
    font-weight: 400;
    font-family: $text-font;

    &::placeholder,
    &::-webkit-input-placeholder, /* Chrome/Opera/Safari */
    &::-moz-placeholder, /* Firefox 19+ */
    &:-ms-input-placeholder, /* IE 10+ */
    &:-moz-placeholder {
      color: $greyish;
      opacity: 1;
    }

    &.ui-state-default {
      border-width: 1px;
      color: $charcoal-grey;
    }
    &.ui-state-hover, &[readonly] {
      background-color: #fff;
    }
    &.ui-state-focus {
      padding-top: 11px;
      padding-bottom: 11px;
      padding-left: 13px;
      border-width: 2px;
      background-color: #fff;
    }
    &[disabled] {
      background-color: $white-smoke;
    }
  }
}

.select_field {
  height: 47px;
  padding: 11px 15px 12px;
  margin: 0;
  box-shadow: none;
  border: 1px solid $pinkish-grey;
  border-radius: 5px;
  font-size: 14px;
  line-height: 24px;
  font-weight: 400;
  font-family: $text-font;
  color: $charcoal-grey;

  &::placeholder,
  &::-webkit-input-placeholder, /* Chrome/Opera/Safari */
  &::-moz-placeholder, /* Firefox 19+ */
  &:-ms-input-placeholder, /* IE 10+ */
  &:-moz-placeholder {
    color: $greyish;
    opacity: 1;
  }

  &:focus {
    padding-top: 10px;
    padding-bottom: 11px;
    padding-left: 14px;
    box-shadow: none;
    border: 2px solid $pinkish-grey;
    background-color: #fff;
  }

  &[disabled] {
    background-color: $white-smoke;
  }

  &.ng-invalid:not(.ng-pristine) {
    border: 1px solid $dark-coral;
  }
}

.input_field {
  height: 47px;
  padding: 11px 15px 12px;
  margin: 0;
  box-shadow: none;
  border: 1px solid $pinkish-grey;
  border-radius: 5px;
  font-size: 14px;
  line-height: 24px;
  font-weight: 400;
  font-family: $text-font;
  color: $charcoal-grey;

  &::placeholder,
  &::-webkit-input-placeholder, /* Chrome/Opera/Safari */
  &::-moz-placeholder, /* Firefox 19+ */
  &:-ms-input-placeholder, /* IE 10+ */
  &:-moz-placeholder {
    color: $greyish;
    opacity: 1;
  }

  &:focus {
    padding-top: 10px;
    padding-bottom: 11px;
    padding-left: 14px;
    box-shadow: none;
    border: 2px solid $pinkish-grey;
    background-color: #fff;
  }

  &[disabled] {
    background-color: $white-smoke;
  }

  &.ng-invalid:not(.ng-pristine) {
    border: 1px solid $dark-coral;
  }
}

.input_group {
  position: relative;
  display: table;
  background-color: #fff;
  border-collapse: separate;
  border-radius: 4px;
  width: 100%;

  .input_field {
    &:focus {
      border-width: 1px;
    }
    &.no-left-border {
      padding-left: 0;
      border-left: none;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
    &.no-right-border {
      padding-right: 0;
      border-right: none;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
  }

  &-addon {
    display: table-cell;
    width: 1%;
    border: 1px solid #ccc;
    border-radius: 4px;
    white-space: nowrap;
    vertical-align: middle;
    font-size: 14px;
    font-weight: 400;
    font-family: $text-font;
    line-height: 24px;
    color: $charcoal-grey;

    &:first-child {
      padding: 10px 3px 11px 15px;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      border-right: 0;
    }

    &:last-child {
      padding: 10px 15px 11px 3px;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      border-left: 0;
    }
  }

  &.disabled {
    background-color: $white-smoke;
  }
}

.text_field {
  display: block;
  width: 100%;
  height: 200px;
  padding: 11px 15px 12px;
  margin: 0;
  box-shadow: none;
  resize: none;
  border: 1px solid $pinkish-grey;
  border-radius: 5px;
  font-size: 14px;
  line-height: 24px;
  font-weight: 400;
  font-family: $text-font;
  color: $charcoal-grey;

  &::placeholder,
  &::-webkit-input-placeholder, /* Chrome/Opera/Safari */
  &::-moz-placeholder, /* Firefox 19+ */
  &:-ms-input-placeholder, /* IE 10+ */
  &:-moz-placeholder {
    color: $greyish;
    opacity: 1;
  }

  &:focus {
    padding-top: 10px;
    padding-bottom: 11px;
    padding-left: 14px;
    box-shadow: none;
    border: 2px solid $pinkish-grey;
    background-color: #fff;
  }

  &[disabled] {
    background-color: $white-smoke;
  }

  &.ng-invalid:not(.ng-pristine) {
    border: 1px solid $dark-coral;
  }
}

.bc-email-template {
  position: relative;

  .cke {
    overflow: hidden;
    margin: 0;
    box-shadow: none;
    resize: none;
    border: 1px solid $pinkish-grey;
    border-radius: 5px;
    font-size: 14px;
    line-height: 24px;
    font-weight: 400;
    font-family: $text-font;
    color: $charcoal-grey;
  }

  .cke_contents {
    border-radius: 5px;
  }

  .cke_top, .cke_bottom {
    display: none;
  }

  .cke_editable {
    height: 105px;
    margin: 11px 15px 12px;
  }

  &.disabled:before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    z-index: 2;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.04);
    border: 1px solid $pinkish-grey;
    border-radius: 5px;
  }

  .ng-invalid:not(.ng-pristine) .cke {
    border: 1px solid $dark-coral;
  }
}

.bc-switch {
  display: block;

  input { display:none; }

  .slider {
    position: relative;
    display: block;
    width: 85px;
    height: 37px;
    background-color: $pinkish-grey;
    box-shadow: 0 3px 0 0 $greyish-two;
    border-radius: 25px;
    line-height: 37px;
    font-weight: normal;

    transition: .4s;

    span {
      position: absolute;
      top: 0;
      z-index: 2;
      display: block;
      width: 52px;
      text-align: center;
      font-size: 14px;
      font-family: $header-font;
      color: #fff;
      &.checked {
        left: 29px;
        display: none;
      }
      &.unchecked {
        display: block;
      }
    }
  }

  .slider:before {
    position: absolute;
    content: "";
    height: 32px;
    width: 32px;
    top: 3px;
    left: 50px;
    border-radius: 25px;
    background-color: #fff;
    transition: .4s;
  }

  input:checked + .slider {
    background-color: $mainTenantColor;
    box-shadow: 0 3px 0 0 $secondTenantColor;
    span.checked {
      display: block;
    }
    span.unchecked {
      display: none;
    }
  }

  input:checked + .slider:before {
    left: 3px;
  }

  input[disabled] + .slider {
    opacity: 0.6;
  }
}


.file_field__item {
  @include u-clearfix;
  font-size: 0;
  .field__label {
    display: block;
  }

  @include breakpoint(sm-min) {
    .file_field__wrap {
      float: left;
      width: calc(100% - 90px);
      padding-right: 12px;
    }
    .bc-btn {
      float: right;
    }
  }
}

.bc-btn.bc-btn_fix-align {
  margin-top: -2px;
  vertical-align: middle;
}

.auth-form {
  padding: 15px 30px 25px;

  background: #ffffff;
  box-shadow: $typical-box-shadow;

  &__separator {
    display: block;
    width: 100%;
    height: 1px;
    margin: 25px 0;
    background: $whisper-grey;
  }

  &__link {
    margin-top: 20px;

    .bc-btn-link {
      font-family: $text-font;
      font-weight: 500;
    }
  }

  &__icon {
    text-align: center;
  }
}

.info-block {
  font-family: $text-font;
  padding: 7px 15px;
  color: $mainTenantColor;
}

.info-block-container {
  .info-block {
    padding-bottom: 0;
  }

  .info-block:last-child {
    padding-bottom: 7px;
  }
}

.input-group-text:first-child {
  border-right: 0;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group-text:last-child {
  border-left: 0;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
