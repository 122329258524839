/* Text styles */

.h1-header {
  margin: 0;
  font-size: 40px;
  line-height: 48px;
  font-weight: 300;
  font-family: $header-font;
  &_charcoal {
    color: $charcoal-grey;
  }
}

.h2-header {
  margin: 0;
  font-size: 20px;
  line-height: 19px;
  font-weight: 700;
  font-family: $header-font;
  &_charcoal {
    color: $charcoal-grey;
  }
  &_nero {
    color: $nero;
  }
}

.h3-header {
  margin: 0;
  font-size: 15px;
  line-height: 19px;
  font-weight: 700;
  font-family: $header-font;
  &_charcoal {
    color: $charcoal-grey;
  }
  &_nero {
    color: $nero;
  }
  &_regular {
    font-weight: 400;
  }
}
.h4-header {
  margin: 0;
  font-size: 14px;
  line-height: 19px;
  font-weight: 400;
  font-family: $header-font;
  &_nero {
    color: $nero;
  }
  &_charcoal {
    color: $charcoal-grey;
  }
}

.h5-header {
  margin: 0;
  font-size: 11px;
  line-height: 19px;
  font-weight: 400;
  font-family: $header-font;
  &_nero {
    color: $nero;
  }
  &_charcoal {
    color: $charcoal-grey;
  }
}

.main-text {
  margin: 0;
  font-size: 14px;
  line-height: 19px;
  font-weight: 400;
  font-family: $text-font;
  &_nero {
    color: $nero;
  }
  &_warm-grey {
    color: $warm-grey;
  }
  &_charcoal {
    color: $charcoal-grey;
  }
}

.page-heading {
  font-family: $header-font;
  font-size: 20px;
  color: $charcoal-grey;
  padding-bottom: 20px;
  font-weight: 500;
}

.warning {
  border-radius: 10px;
  background-color: #fffdf3;
  border: solid 2px $sunflower-yellow;
  width: 100%;
  position: relative;
  display: flex;
  padding: 10px 5px 5px 5px;
  margin: 0 0 10px 0;

  .title {
    line-height: 1.71;
    text-align: left;
    color: $charcoal-grey;
  }

  .message {
    font-family: $text-font;
    font-size: 14px;
    line-height: 1.71;
    text-align: left;
    color: $warm-grey;
  }

  .close {
    position: absolute;
    right: 5px;
    top: 5px;
    width: 18px;
    height: 18px;
  }
  .message-body {
    flex: 1;
    padding: 2px;
  }

  .warning-sign {
    margin: 5px;
    width: 26px;
    height: 23px;
  }
}

.simple-text {
  font-family: $text-font;
  font-size: 14px;
  line-height: 1.71;
  color: $charcoal-grey;
  font-weight: 400;
}
