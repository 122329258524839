.status-label {
  display: inline-block;
  width: 100%;
  max-width: 100px;
  padding-top: 7px;
  padding-bottom: 6px;
  border-radius: 5px;
  text-align: center;
  font-size: 13px;
  line-height: 19px;
  font-weight: 700;
  font-family: $text-font;
  color: #fff;
  background: $greyish;

  &_incomplete {
    background: $sunflower-yellow;
  }
  // TODO check this color with Lucas
  &_complete {
    background: $light-sky-blue;
  }
  &_done {
    background: $dark-sky-blue;
  }
  &_accepted {
    background: $mainTenantColor;
  }
  &_rejected {
    background: $dark-coral;
  }

  &_wide {
    width: 100%;
  }
}
