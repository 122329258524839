.flex-c-b {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.flex-c-c {
  display: flex;
  align-items: center;
  justify-content: center;
}

.flex-d-c {
  display: flex;
  align-items: center;
}

.flex {
  display: flex;
}

.height {
  height: 100%;
}

.divider {
  width: 100%;
  height: 1px;
  background-color: $pale-grey;
  margin: auto;
}

.u-unstyled-btn {
  padding: 0;
  outline: none !important;
  border: none;
  box-shadow: none;
  background: transparent;
}

.u-unstyled-list {
  padding: 0;
  margin: 0;
  list-style: none;
}

.u-unstyled-link {
  text-decoration: none;
}

.padding-top-30 {
  padding-top: 30px;
}
