.icon-link {
  font-size: 14px;
  line-height: 19px;
  font-weight: 400;
  font-family: $text-font;
  color: $charcoal-grey;

  &__icon {
    display: inline-block;
    vertical-align: -2px;
    color: $mainTenantColor;
    &:hover {
      color: $secondTenantColor;
    }
  }

  &:hover {
    text-decoration: none;
    color: $mainTenantColor;
  }
}

.text-link {
  font-size: 14px;
  line-height: 19px;
  font-weight: 400;
  font-family: $text-font;
  cursor: pointer;

  &_custom {
    color: $mainTenantColor;
  }

  &_hidden {
    color: inherit;

    &:hover {
      color: inherit;
      text-decoration: underline;
    }
  }
}

.back-link {
  display: inline-block;
  line-height: 24px;
  cursor: pointer;
  &:before {
    content: '';
    display: inline-block;
    vertical-align: 1px;
    width: 0;
    height: 0;
    margin-right: 8px;
    border-style: solid;
    border-width: 4px 6px 4px 0;
    border-color: transparent $mainTenantColor transparent transparent;
  }

  &:hover {
    &:before {
      border-color: transparent $secondTenantColor transparent transparent;
    }
  }
}
