@import "./assets/stylesheet/core/reset";
@import "./assets/stylesheet/core/variables";
@import "./assets/stylesheet/core/mediaquery";
@import "./assets/stylesheet/core/mixins";

body {
  background: $section-background;
  color: $charcoal-grey;
}

@import "./assets/stylesheet/core/helpers";
@import "./assets/stylesheet/core/grid";

@import "./assets/stylesheet/components/buttons";
@import "./assets/stylesheet/components/icons";
@import "./assets/stylesheet/components/link";
@import "./assets/stylesheet/components/status-label";
@import "./assets/stylesheet/components/form";
@import "./assets/stylesheet/components/dx-form";
@import "./assets/stylesheet/components/dropdown";
@import "./assets/stylesheet/components/actions-dropdown";
@import "./assets/stylesheet/components/tabs";
@import "./assets/stylesheet/components/typography";
@import "./assets/stylesheet/components/panel";
@import "./assets/stylesheet/components/bc-toaster";
@import "./assets/stylesheet/components/dx-new-grid";

@import "./assets/stylesheet/templates/new_modal-dialog.scss";

@import "./assets/stylesheet/pages/signup";
