.bc-actions-dropdown {
  position: relative;
  cursor: pointer;

  &:hover {
    .list {
      visibility: visible;
    }
  }
}

.bc-actions-dropdown .trigger {
  padding: 6px 0;
  width: 20px;
  margin: auto;

  font-size: 0;
  line-height: 0;
  color: transparent;

  span {
    display: block;
    position: relative;
    width: 4px;
    height: 4px;
    background: #d8d8d8;
    border: 1px solid #979797;
    border-radius: 50%;

    &:before {
      content: '';
      position: absolute;
      left: 6px;
      top: -1px;
      width: 4px;
      height: 4px;
      background: #d8d8d8;
      border: 1px solid #979797;
      border-radius: 50%;
    }
    &:after {
      content: '';
      position: absolute;
      left: 13px;
      top: -1px;
      width: 4px;
      height: 4px;
      background: #d8d8d8;
      border: 1px solid #979797;
      border-radius: 50%;
    }
  }
}

.bc-actions-dropdown .trigger.vertical {
  transform: rotate(90deg);
}

.bc-actions-dropdown .list {
  visibility: hidden;
  min-width: 140px;
  position: absolute;
  left: 0;
  top: 100%;
  z-index: 1;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.05);

  &.right-align {
    right: 0;
    left: auto;
  }

  &.top-align {
    right: 20px;
    left: auto;
    top: 0;
  }

  .link {
    display: flex;
    align-content: center;
    align-items: center;
    width: 100%;
    height: 34px;
    padding: 0 20px;
    background: #fff;
    border: 0;
    white-space: nowrap;
    font-family: $header-font;
    font-size: 13px;
    font-weight: 300;
    cursor: pointer;
    text-align: left;

    &:hover {
      background: #e6e6e6;
    }
  }
}
