
/* Color palette */

$greenish-teal: #4ccead;
$greeny-blue-two: #41c69d;
$greeny-blue: #41af93;
$ocean-green: #348c75;

$white: #ffffff;
$whisper-grey: #eee;
$pale-grey: #e7eaed;
$white-smoke: #f6f6f6;
$white-smoke2: #f8f8f8;
$pinkish-grey: #ccc;
$pinkish-grey-two: #bbb;
$greyish-two: #aeaeae;
$greyish: #aaa;
$warm-grey-two: #8b8b8b;
$warm-grey: #777;
$warm-grey-three: #888;
$charcoal-grey: #333;
$nero: #262626;
$greyish-brown: #545454;

$dark-coral: #ce4c4c;
$sunflower-yellow: #ffd200;
$ugly-yellow: #e0b800;
$dark-sky-blue: #4c9fe2;

$dusty-blue: #607cb3;

// TODO check this color with Lucas
$light-sky-blue: #87cefa;

$header-font: 'Montserrat', sans-serif;
$text-font: 'Roboto', sans-serif;

$border-color: #e7e7e7;
$typical-box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.05);
$section-background: #f9f9f9;
$section-background2: #ededed;
$secondary-background: #f6f6f6;

$mainTenantColor: #2a2a32;
$secondTenantColor: #727272;

$dark-coral: #ce4c4c;
$sunflower-yellow: #ffd200;
$ugly-yellow: #e0b800;
$light-green: #e1f0e5;
$light-orange: rgba(244, 179, 3, 0.19);
$light-red: #fddece;
$darker-red: #f47107;
$green: #6cd331;
$orange: #f4b303;
$dark-sky-blue: #4c9fe2;
