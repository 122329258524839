// @include breakpoint() {
//
// }
@mixin breakpoint($point) {
  @if $point == md-max {
    @media (max-width: 992px) {
      @content;
    }
  }

  @if $point == md-min {
    @media (min-width: 992px) {
      @content;
    }
  }

  @if $point == lg-min {
    @media (min-width: 1200px) {
      @content;
    }
  }

  @if $point == sm-min {            // styles for screen which width > 768px
    @media (min-width: 768px) {
      @content;
    }
  }
}
